import {Action, NgxsOnInit, Selector, State, StateContext} from "@ngxs/store"
import {inject, Injectable} from "@angular/core"
import AppFacade from "../../../../store/facade/app.facade"
import CdkFilterInterface from "../../../../cdk/interface/sidebar/cdk-filter.interface"
import {ActionSetAuthorization} from "./auth.action"
import CdkAuthorizationInterface from "../../../../cdk/interface/cdk-authorization.interface"
import CdkAuthorizationModel from "../../../../cdk/model/cdk-authorization-model"


export interface AuthStateInterface extends CdkAuthorizationInterface {
}

@State<AuthStateInterface>({
  name: 'authState',
  defaults: new CdkAuthorizationModel
})
@Injectable()
export default class AuthState {
  private readonly appFacade: AppFacade = inject(AppFacade)

  @Selector()
  static getAuthorization(state: AuthStateInterface): CdkFilterInterface | Partial<AuthStateInterface> {
    return state
  }

  @Action(ActionSetAuthorization)
  setAuthorization(context: StateContext<AuthStateInterface>, {payload}: ActionSetAuthorization): void {
    const store = {...context.getState(), ...payload}
    context.patchState(store)
    this.appFacade.setAuthorization(store)
  }
}
