import {Expose, plainToInstance, Transform, Type} from "class-transformer"
import CdkAuthorizationInterface from "../interface/cdk-authorization.interface"
import CdkUserInterface from "../interface/cdk-user-interface"
import CdkUserModel from "./cdk-user-model"
import "reflect-metadata"

export default class CdkAuthorizationModel implements CdkAuthorizationInterface {

  @Expose()
  @Transform(({obj}) => obj.Token)
  token: string = ''

  @Expose()
  @Type(() => CdkUserModel)
  @Transform(({obj}) => plainToInstance(CdkUserModel, obj, {excludeExtraneousValues: true}))
  user: CdkUserInterface = new CdkUserModel
}
